import React, { useEffect, useState, useRef } from "react";
import { register } from "swiper/element/bundle";
import { useLocation, Link } from "react-router-dom";

// import { useLocation, Link } from "react-router-dom";

import "./page.styles.scss";

import Menu from "../components/menu.component";
import SmallMenu from "../components/menu-small.component";
import FullScreen from "../components/full-screen.component";
import Footer from "../components/footer.component";

register();

const Frontpage = ({ isSmall }) => {
  const [postContent, setPostContent] = useState(null);
  const [frontpageContent, setFrontpageContent] = useState(null);
  const [pageImages, setPageImages] = useState(null);
  const [pageText, setPageText] = useState(null);
  // console.log(isSmall);
  // const swiperElRef = useRef(null);

  // useEffect(() => {
  //   if (swiperElRef.current) {
  //     // listen for Swiper events using addEventListener
  //     swiperElRef.current.addEventListener("swiperprogress", (e) => {
  //       const [swiper, progress] = e.detail;
  //       console.log(progress);
  //     });

  //     swiperElRef.current.addEventListener("swiperslidechange", (e) => {
  //       console.log("slide changed");
  //     });
  //   }
  // }, []);

  //   ?slug=frontpage

  useEffect(() => {
    async function loadFrontpage() {
      const response = await fetch(
        "https://dreamingoutloud.it/wordpress/wp-json/wp/v2/pages/?slug=frontpage"
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }

      const posts = await response.json();
      setFrontpageContent(posts);
    }

    async function loadPosts() {
      const response = await fetch(
        "https://dreamingoutloud.it/wordpress/wp-json/wp/v2/posts?filter[posts_per_page]=4"
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }

      const posts = await response.json();
      setPostContent(posts);
    }
    loadFrontpage();
    loadPosts();
    // loadFrontpageImage();
  }, []);

  function extractImgsAndSpecificTags(html) {
    // Parse the HTML string into a DOM document
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Select all figure elements and img tags within them
    const figureElements = doc.querySelectorAll("figure");

    // Array to store img tags
    const imgSrcs = [];

    // Loop through each figure element
    figureElements.forEach((figure) => {
      const img = figure.querySelector("img");
      if (img) {
        imgSrcs.push(img.getAttribute("src"));
        // imgTags.push(img.outerHTML); // Add img tag to array
        figure.remove(); // Remove the figure element from the document
      }
    });

    // Select all p and h2 elements remaining in the document
    const remainingElements = Array.from(doc.querySelectorAll("p, h2")).map(
      (el) => el.outerHTML
    );

    return { imgSrcs, remainingElements };
  }

  useEffect(() => {
    if (!frontpageContent) return;
    // console.log(pageContent);
    let content = frontpageContent[0].content.rendered;
    // let images = extractImgsAndRest(content);

    const result = extractImgsAndSpecificTags(content);
    console.log(result);
    setPageImages(result.imgSrcs);
    setPageText(result.remainingElements);

    // define image gallery, title and text content from
  }, [frontpageContent]);

  const FrontpageWedding = ({ content }) => {
    // console.log("hello");
    // console.log(content);
    return (
      <div className="frontpage-single-wedding">
        <Link to={"/portfolio/" + content.slug}>
          <div className="frontpage-single-wedding-image">
            <div className="frontpage-single-wedding-border"></div>
            <img src={content.fimg_url} />
          </div>
          <div className="frontpage-single-wedding-title">
            {content.title.rendered}
          </div>
        </Link>
        <div
          className="frontpage-single-wedding-description"
          dangerouslySetInnerHTML={{
            __html: content.excerpt.rendered,
          }}
        ></div>
      </div>
    );
  };

  if (!postContent || !pageText) return <></>;
  return (
    <div>
      {isSmall ? <SmallMenu /> : <Menu />}
      <FullScreen
        imageUrl={frontpageContent[0].fimg_url}
        title="Dreaming Out Loud"
      />
      <div className="frontpage-content-wrapper">
        {/* <div
          className="frontpage-content"
          dangerouslySetInnerHTML={{
            __html: frontpageContent[0].content.rendered,
          }}
        ></div> */}

        <div className="frontpage-content">
          <div className="frontpage-image-wrapper">
            {pageImages.map((image) => {
              return (
                <div className="wp-block-image">
                  <img src={image} />
                </div>
              );
            })}
          </div>
          <div className="frontpage-text-wrapper">
            {pageText.map((el) => {
              return (
                <div
                  className="text-element"
                  dangerouslySetInnerHTML={{
                    __html: el,
                  }}
                ></div>
              );
            })}
          </div>
        </div>

        <div className="frontpage-wedding-examples">
          {/* {console.log(testContent)} */}
          {postContent
            .filter((post, index) => index < 3)
            .map((post) => {
              return <FrontpageWedding content={post} />;
            })}
        </div>
        <div className="frontpage-wedding-examples-more-wrapper">
          <Link to={"/portfolio"}>
            <div className="frontpage-wedding-examples-more-button">
              See more weddings
            </div>
          </Link>
        </div>
      </div>
      <div className="quote-wrapper">
        <div className="quote-content-wrapper">
          <div className="quote-content">
            “He felt now that he was not simply close to her, but that he did
            not know where he ended and she began”
          </div>
          <div className="quote-name">Leo Tolstoj</div>
        </div>
        <div className="quote-background"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Frontpage;

{
  /* <swiper-container
ref={swiperElRef}
slides-per-view="1"
navigation="true"
pagination="true"
>
<swiper-slide>Slide 1</swiper-slide>
<swiper-slide>Slide 2</swiper-slide>
<swiper-slide>Slide 3</swiper-slide>
</swiper-container> */
}
