import React, { useEffect, useState, useRef } from "react";

import "./footer.styles.scss";
import { FaInstagram } from "react-icons/fa";

import { FaRegEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-left">
        © Liz Tuson 2024
        <br />
        P.I. 01335340525
      </div>

      <div className="footer-center">
        <img
          className="footer-logo"
          src={process.env.PUBLIC_URL + "/img/logo_icon_02.png"}
        />
      </div>

      <div className="footer-right">
        <a
          className="instagram-icon"
          href="https://www.instagram.com/liz_dreamingoutloud/"
        >
          <FaInstagram /> Instagram
        </a>
        <br />
        <a href="mailto:info@dreamingoutloud.it">
          <FaRegEnvelope /> Email
        </a>
      </div>
    </div>
  );
};

export default Footer;
